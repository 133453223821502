import React from "react";
import "../../styles/success.scss";

const TrustpilotStar = () => (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8766 9.56937H27.3024L18.8692 15.4799L13.6562 19.129L5.21292 25.0395L8.44333 15.4799L0 9.55967L10.4258 9.56937L13.6562 0L16.8766 9.56937ZM13.6562 19.129L22.0895 25.0395L18.8692 15.4799L19.5937 17.6441L13.6562 19.129Z"
      fill="white"
    />
  </svg>
);

const SuccessPage = ({ type = null, tp_link = null, rating = null }: any) => {
  const getTitleFromType = () => {
    if (type === null) {
      return "";
    }
    switch (type) {
      case "RecentlyMatched":
      case "RecentlyMatchedLO":
        return "Recently Matched Survey";
      case "RecentlyMet":
        return "Recently Met Survey";
      case "OnMarket":
        return "On Market Survey";
      case "Lost":
        return "Lost Survey";
      case "Closed":
      case "ClosedLO":
        return "Closed Survey";
      case "DataSender":
        return "Data Sender";
      default:
        return "Clever Survey";
    }
  };
  const getRatingCheck: any = () => {
    if (type === "Closed" && rating) {
      if (parseInt(rating, 10) < 10) {
        return (
          <p>
            Thank you for your feedback! We appreciate you choosing Clever for
            your real estate needs.
            <br />
            <br />
            If you would like to discuss your experience further with a member
            of our staff, you can reach us at{" "}
            <a href="tel:6143632845">614-363-2845</a> or{" "}
            <a href="mailto:team@movewithclever.com">team@movewithclever.com</a>
            .
          </p>
        );
      }
      return (
        <>
          <p>
            Thank you for your feedback! We’re thrilled to hear you had a great
            experience using Clever for your real estate needs!
            <br />
            <br /> We would appreciate it if you left us a public review on
            Trustpilot as well! This lets more buyers and sellers (like you)
            find out about Clever.
            <br />
            <br />
            Plus, every month we give away a{" "}
            <span className="dollarValue">$250</span> gift card to one customer
            that left us a review.
            <br />
            <br /> Click the link below to leave us a review on Trustpilot:
          </p>
          <a
            href={tp_link || ""}
            target="_blank"
            rel="noopener noreferrer  nofollow"
            className="trustpilotLink"
          >
            <TrustpilotStar /> Trustpilot Review
          </a>
        </>
      );
    }
    return <></>;
  };

  const getBodyCopyFromType = () => {
    if (type === null) {
      return "";
    }

    switch (type) {
      case "RecentlyMatched":
      case "RecentlyMet":
      case "OnMarket":
        return (
          <p>
            If you would like to discuss your experience further with a member
            of our staff, you can reach us at{" "}
            <a href="tel:6143632845">614-363-2845</a> or{" "}
            <a href="mailto:team@movewithclever.com">team@movewithclever.com</a>
            .
          </p>
        );
      case "ClosedLO":
      case "RecentlyMatchedLO":
        return (
          <div className="column">
            <p>
              If you would like to discuss your experience further with a member
              of our staff, please reach out to your account manager:
            </p>
            <div className="row-spaced">
              <div className="column padded">
                <p>Dan Hartner</p>
                <a href="tel:3142074548">314-207-4548</a>
                <a href="mailto:dan.hartner@movewithclever.com">
                  dan.hartner@movewithclever.com
                </a>
              </div>
              <div className="column padded">
                <p>Annamae Gilberti</p>
                <a href="tel:3148889705">314-888-9705</a>
                <a href="mailto:annamae.gilberti@movewithclever.com">
                  annamae.gilberti@movewithclever.com
                </a>
              </div>
            </div>
          </div>
        );
      case "ClosedUnattachedLO":
        return (
          <p>
            Thank you for your feedback! We appreciate you choosing Clever for
            your real estate needs.
            <br />
            <br />
            If you would like to discuss your experience further with a member
            of our staff, you can reach us at{" "}
            <a href="tel:6143632845">614-363-2845</a> or{" "}
            <a href="mailto:team@movewithclever.com">team@movewithclever.com</a>
            .
          </p>
        );
      case "Lost":
        return (
          <p>
            Thank you! As a growing company, your feedback helps us ensure we’re
            taking steps in the right direction. We’re sorry to hear things
            didn’t work out for you this time around, but we hope that you’ll
            consider us again in the future.
            <br />
            <br />
            If you would like to discuss your experience further with a member
            of our staff, you can reach us at{" "}
            <a href="tel:6143632845">614-363-2845</a> or{" "}
            <a href="mailto:team@movewithclever.com">team@movewithclever.com</a>
            .
          </p>
        );
      case "Closed":
        return getRatingCheck();
      case "DataSender":
        return <p>Successfully Sent Data to Mortgage Partner</p>;
      default:
        return <p />;
    }
  };
  return (
    <div>
      <div className="defaultFormSuccessContainer">
        <h2>{getTitleFromType()}</h2>
        <div className="defaultFormBody successContainer">
          <h4>
            Thank you!{" "}
            {type === "Closed" || type === "ClosedUnattachedLO" ? "🎉" : ""}
          </h4>
          {getBodyCopyFromType()}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
