import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import SuccessPage from "../components/Success/SuccessPage";
import "../styles/form.scss";

const Success = ({ location }: any) => {
  let type = null;
  let rating = null;
  let tp_link = null;
  if (location.state) {
    type = location.state.type || null;
    rating = location.state.rating || null;
    tp_link = location.state.tp_link || null;
  }

  return (
    <AuthenticationLayout>
      <SuccessPage type={type} tp_link={tp_link} rating={rating} />
    </AuthenticationLayout>
  );
};

export default Success;
